var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cadastro-usuario"},[_c('v-container',{attrs:{"fluid":""}},[_c('h1',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"titulo"},[_vm._v("Bilhetes")]),_c('div',{staticClass:"box-table"},[_c('v-skeleton-loader',{attrs:{"loading":_vm.loadingSkeleton,"transition":'fade-transition',"height":"500","type":"table"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.listaItens,"width":"300px","height":"auto","flat":"","dense":"","hide-default-footer":"","items-per-page":_vm.itemsPerPage,"loading":_vm.loadingTable},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('BrToolBar',{attrs:{"configFilter":{ 
                disableTextField: true 
              },"addlFilter":true},on:{"search":_vm.buscar},scopedSlots:_vm._u([{key:"btnSelect",fn:function(){return [_c('v-row',{staticClass:"justify-end"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.customTokens),expression:"customTokens"}],attrs:{"append-icon":"search","label":'Código Bilhete',"single-line":"","hide-details":"","solo":"","dense":"","color":"primary"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchCodTickets(_vm.search)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{staticClass:"select-status",attrs:{"items":_vm.itemsStatus,"label":"Status do Bilhete","multiple":"","dense":"","hide-details":"","solo":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('FiltroRangeDate',{staticClass:"mr-3 range-date",staticStyle:{"width":"100%"},attrs:{"value":_vm.rangeDate,"labelBtn":'Selecionar Data',"actionLabels":{ apply: 'OK' },"disableBtnClear":false},on:{"input":_vm.filtrar}})],1)],1)]},proxy:true}])})]},proxy:true},{key:"item.Valor",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.Valor))+" ")]}},{key:"item.PossivelRetorno",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.PossivelRetorno))+" ")]}},{key:"item.Cotacao",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.Cotacao))+" ")]}},{key:"item.Comissao",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.Comissao))+" ")]}},{key:"item.Data",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("datas")(item.Data))+" ")]}},{key:"item.prizes",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getValorPremio(item.prizes))+" ")]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",class:{ 'bilhete-cancelado': _vm.isActive(item) },attrs:{"small":"","outlined":"","color":_vm.variables.colorError,"rounded":"","shaped":"","loading":_vm.loadingBtns},on:{"click":function($event){return _vm.handlerCancelarBilhete(item)}}},[_vm._v(" "+_vm._s(item.canceled ? 'Cancelado' : 'Cancelar')+" ")]),_c('v-btn',{staticClass:"mr-2",class:{ 'bilhete-premiado': _vm.isActiveBilhete(item) },attrs:{"small":"","rounded":"","shaped":"","outlined":"","color":_vm.variables.colorSecondary},on:{"click":function($event){return _vm.handlerBilhetePremiado(item)}}},[_vm._v(" "+_vm._s(item.winner ? 'Premiado' : 'Premiar')+" ")])]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"wrapper-footer"},[_c('div',{staticClass:"qtd-paginacao"},[_vm._v(" "+_vm._s(_vm.pageStart)+" - "+_vm._s(_vm.pageStop)+" de "+_vm._s(_vm.totalItens)+" ")]),(_vm.numberOfPages > 1)?_c('v-pagination',{staticClass:"box-table-paginacao",attrs:{"length":_vm.numberOfPages,"circle":"","disabled":_vm.disabled,"total-visible":7},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)]},proxy:true}])})],1)],1)]),_c('ModalPremiar',{attrs:{"activeModal":_vm.showModalPremiarBilhete,"bilhete":_vm.bilhetePremiado},on:{"closeModal":_vm.closeModal,"getBilhetes":_vm.getBilhetes}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }