<template>
  <div class="cadastro-usuario">
    <v-container
      fluid
    >
      <h1 class="titulo" v-translate>Bilhetes</h1>
      <div class="box-table">
        <v-skeleton-loader
          :loading="loadingSkeleton"
          :transition="'fade-transition'"
          height="500"
          type="table">
          <v-data-table
            :headers="headers"
            :items="listaItens"
            width="300px"
            height="auto"
            flat
            dense
            hide-default-footer
            :items-per-page.sync="itemsPerPage"
            :loading="loadingTable"
          >
            <template v-slot:top>
              <BrToolBar @search="buscar" 
                :configFilter="{ 
                  disableTextField: true 
                }" 
                :addlFilter="true">
                <template v-slot:btnSelect>
                  <v-row class="justify-end">
                    <v-col cols="12" md="3">
                      <v-text-field
                        append-icon="search"
                        :label="'Código Bilhete'"
                        single-line
                        hide-details
                        v-on:keyup.enter="searchCodTickets(search)"
                        v-model="search"
                        solo
                        dense
                        v-mask="customTokens"
                        color="primary" />
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-select
                        v-model="status"
                        :items="itemsStatus"
                        label="Status do Bilhete"
                        multiple
                        dense
                        hide-details
                        solo
                        class="select-status"
                      >
                      </v-select>
                    </v-col>

                    <v-col cols="12" md="3">
                      <FiltroRangeDate
                        class="mr-3 range-date"
                        @input="filtrar"
                        :value="rangeDate"
                        :labelBtn="'Selecionar Data'"
                        :actionLabels="{ apply: 'OK' }"
                        style="width: 100%;"
                        :disableBtnClear="false"
                      />
                    </v-col>
                  </v-row>
                </template>
              </BrToolBar>
            </template>

            <template v-slot:item.Valor="{ item }">
              {{ item.Valor | currency }}
            </template>

            <template v-slot:item.PossivelRetorno="{ item }">
              {{ item.PossivelRetorno | currency }}
            </template>

            <template v-slot:item.Cotacao="{ item }">
              {{ item.Cotacao | currency }}
            </template>

            <template v-slot:item.Comissao="{ item }">
              {{ item.Comissao | currency }}
            </template>

            <template v-slot:item.Data="{ item }">
              {{ item.Data | datas }}
            </template>

            <template v-slot:item.prizes="{ item }">
              {{ getValorPremio(item.prizes) }}
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn
                small
                outlined 
                :color="variables.colorError"
                rounded
                shaped
                class="mr-2"
                :class="{ 'bilhete-cancelado': isActive(item) }"
                @click="handlerCancelarBilhete(item)"
                :loading="loadingBtns"
              >
                {{ item.canceled ? 'Cancelado' : 'Cancelar' }}
              </v-btn>
              <v-btn
                small
                rounded
                shaped
                outlined
                :color="variables.colorSecondary"
                :class="{ 'bilhete-premiado': isActiveBilhete(item) }"
                @click="handlerBilhetePremiado(item)"
                class="mr-2"
              >
                {{ item.winner ? 'Premiado' : 'Premiar' }}
              </v-btn>
            </template>

            <template v-slot:footer>
              <div class="wrapper-footer">
                <div class="qtd-paginacao">
                  {{ pageStart }}
                  -
                  {{ pageStop }}
                  de
                  {{ totalItens }}
                </div>
                <v-pagination
                  v-model="page"
                  :length="numberOfPages"
                  circle
                  :disabled="disabled"
                  :total-visible="7"
                  class="box-table-paginacao"
                  v-if="numberOfPages > 1"></v-pagination>
              </div>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
    </v-container>

    <ModalPremiar 
      :activeModal="showModalPremiarBilhete"
      :bilhete="bilhetePremiado"
      @closeModal="closeModal"
      @getBilhetes="getBilhetes"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'
import { validationMixin } from 'vuelidate'
import { required  } from 'vuelidate/lib/validators'
// import Events from '@/core/service/events'
import variables from '@/assets/styles/helpers/_variables.scss'
import moment from 'moment'
import listagemTables from '@/views/mixins/listagemTables'
import { errorSnackbar, successSnackbar, currency } from '@/core/service/utils'


/**
 * MIXIN
 */
// import listagemTables from '@/views/mixins/listagemTables'

moment.locale('pt-BR')

export default {
  name: 'EsportesBilhetes',
  components: {
    // autoCompleteEntities: () => import('@/views/components/autoCompleteEntities')
    FiltroRangeDate: () => import('@/views/components/filtroRangeDate'),
    BrToolBar: () => import('./components/toolbar'),
    ModalPremiar: () => import('./components/modalPremiar')
  },
  mixins: [validationMixin, listagemTables],
  directives: {mask},
  data: () => ({
    loadingSkeleton: false,
    loadingTable: false,
    loadingBtns: false,
    page: 1,
    showModalPremiarBilhete: false,
    bilhetePremiado: {},
    status: [],
    headers: [
      { sortable: true, class: 'table-header', text: 'Código', value: 'codigoId' },
      { sortable: true, class: 'table-header', text: 'Qtd. Apostas', value: 'ApostasCount' },
      { sortable: true, class: 'table-header', text: 'Valor', value: 'Valor' },
      { sortable: true, class: 'table-header', text: 'Comissão', value: 'Comissao' },
      { sortable: true, class: 'table-header', text: 'Cotação', value: 'Cotacao' },
      { sortable: true, class: 'table-header', text: 'Nome Cliente', value: 'NomeCliente' },
      { sortable: true, class: 'table-header', text: 'Possível Retorno', value: 'PossivelRetorno' },
      { sortable: true, class: 'table-header', text: 'Valor Prêmio', value: 'prizes' },
      { sortable: true, class: 'table-header', text: 'Data', value: 'Data' },
      { align: 'end', sortable: false, class: 'table-header', text: '', value: 'actions' }
    ],
    rangeDate: {
      start: moment().format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD')
    },
    customTokens: {
      mask: 'CCCC-CCCC',
      tokens: {
        'C': {
          pattern: /[0-9a-zA-Z]/,
          transform: function(v) {
            return v.toLocaleUpperCase();
          }
        }
      }
    },
  }),

  mounted () {
    this.loadingSkeleton = true
    this.getData({
        page: 1,
        size: this.$store.getters.ItensPaginacao,
        dtStart: this.rangeDate.start,
        dtEnd: this.rangeDate.end
      })
      .finally(() => this.loadingSkeleton = false)
  },

  watch: {
    page (e) {
      this.paginacao(e)
    },

    status (val) {
      this.loadingPag = true
      this.page = 1
      this.getData({ status: val, dtStart: this.rangeDate.start, dtEnd: this.rangeDate.end })
        .finally(() => this.loadingPag = false)
    }
  },

  computed: {
    ...mapGetters('bilhetes', ['listaItens', 'totalItens']),
    variables: () => variables,

    itemsStatus: () => [
      { text: 'Prêmiados', value: 'P' },
      { text: 'Cancelados', value: 'C' }
    ],
  },

  methods: {
    ...mapActions('bilhetes', ['getData', 'cancelarBilhete']),
    init () {},

    paginacao (pag) {
      this.loadingTable = true
      this.disabled = true
      this.setPage(pag)
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
      this.getData({ status: this.status, page: pag, size: this.$store.getters.ItensPaginacao, dtStart: this.rangeDate.start, dtEnd: this.rangeDate.end })
      .finally(() => {
        this.loadingTable = false
        this.disabled = false
      })
    },

    filtrar (e) {
      this.rangeDate = {
        start: e.start,
        end: e.end
      }

      this.loadingTable = true
      this.getData({ status: this.status, page: this.page, size: this.$store.getters.ItensPaginacao, dtStart: this.rangeDate.start, dtEnd: this.rangeDate.end })
        .finally(() => this.loadingTable = false)
    },

    handlerCancelarBilhete (bilhete) {
      const msg = {
        cancel: `Tem certeza que deseja cancelar o bilhete:\n${bilhete.codigoId}`,
        notCancel: `Tem certeza que deseja reativar o bilhete:\n${bilhete.codigoId}`
      }

      const typeMessage = bilhete.canceled ? 'notCancel' : 'cancel'

      this.$swal({
        icon: 'warning',
        text: msg[typeMessage],
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      })
      .then((result) => {
        if (result.value) {
          const data = {
            cancellationStatus: bilhete.canceled ? 0 : 1,
            bilheteId: bilhete.bilhete_id,
            resultId: bilhete.resultId
          }

          this.loadingBtns = true
          this.cancelarBilhete(data)
            .then(() => {
              successSnackbar('Bilhete cancelado com sucesso!')
              this.loadingTable = true
              this.getData({ status: this.status, page: this.page, size: this.$store.getters.ItensPaginacao, dtStart: this.rangeDate.start, dtEnd: this.rangeDate.end })
                .finally(() => this.loadingTable = false)
            })
            .catch((error) => errorSnackbar(error.message))
            .finally(() => this.loadingBtns = false)
        }
      })
    },

    handlerBilhetePremiado (bilhete) {
      this.showModalPremiarBilhete = true
      this.bilhetePremiado = bilhete
    },

    isActive (bilhete) {
      return !!bilhete.canceled
    },

    isActiveBilhete (bilhete) {
      return !!bilhete.winner
    },

    closeModal () {
      this.showModalPremiarBilhete = false
    },

    getBilhetes () {
      this.showModalPremiarBilhete = false
      successSnackbar('Bilhete prêmiado com sucesso!')
      this.loadingTable = true
      this.getData({
        page: this.page,
        size: this.$store.getters.ItensPaginacao,
        dtStart: this.rangeDate.start,
        dtEnd: this.rangeDate.end
      })
      .finally(() => this.loadingTable = false)
    },

    searchCodTickets (codigo) {
      this.showModalPremiarBilhete = false
      this.loadingTable = true
      this.getData({ bilheteId: codigo })
        .finally(() => this.loadingTable = false)
    },

    getValorPremio (prize) {
      if (!prize) return '-'
      return currency(prize)
    }
  },

  validations () {
    return  {
      birthdayMonth: { required },
    }
  },

  beforeDestroy () {
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/components/tableWithVDeep';
  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }

  .box-table {
    // border-top: 5px solid $colorPrimary;
    // border-top-left-radius: 0 !important;
    // border-top-right-radius: 0 !important;

    .v-data-table-header-mobile {
      display: none;
    }

    &.box-table-gecom {
      .v-data-table > .v-data-table__wrapper > table > tbody > tr > th {
        height: auto;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }

  .fab-cadastrar-usuario {
    position: fixed;
    z-index: 1;
    bottom: 25px;
    right: 5px;

    @media (min-width: 599px) {
      display: none !important;
    }
  }

  .range-date::v-deep {
    .v-btn {
      height: 38px !important;
      width: 100%;
    }
  }

  .select-status::v-deep {
    .v-input__slot {
      margin-bottom: 0 !important;
    }
  }

  .bilhete-cancelado {
    background-color: $colorError;
    color: #FFF !important;
  }
  
  .bilhete-premiado {
    background-color: $colorSecondary;
    color: #FFF !important;
  }
</style>
